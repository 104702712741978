import React, {
  useCallback, useContext, useMemo, useRef,
} from 'react';

import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Tooltip } from '@tmap/mmm-style-guide/src/Tooltip';
import { Chip } from '@tmap/mmm-style-guide/src/Chip';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { useTheme } from '@tmap/mmm-style-guide/src/Theming';

import locationLabelText from '@tmap/mmm-core/locationLabelText';
import { toKey, types } from '@tmap/mmm-core/entityKey';
import { LeadStatus, leadStatusDetails } from '@tmap/mmm-core/leads/status';

import LinkCard from '../linkCard';
import ResponsivePicture from '../responsivePicture';
import findImpressionFeature from '../../lib/findImpressionFeature';
import { getTagPills } from '../../lib/tags';
import { offerTypeFromValue } from '../../lib/filterTypes';
import getPrimaryIncentiveImage from '../../lib/getPrimaryIncentiveImage';
import useImpressions, { ImpressionTracker } from '../../hooks/useImpressions';
import IncompleteTasksCTA from './incompleteTasksCTA';
import IncentiveCTAButton from './incentiveCTAButton';
import OfferProgressContext from './offerProgressContext';

const CardContent = styled(Box, {
  shouldForwardProp: (p) => p !== 'vertical',
})(({ theme, vertical }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  [vertical ? '&' : theme.breakpoints.down('md')]: {
    flexFlow: 'column nowrap',
    height: '100%',
  },
}));

const ContentPlacement = styled(Box, {
  shouldForwardProp: (p) => p !== 'vertical',
})(({ theme, vertical }) => ({
  flexGrow: '1',
  padding: theme.spacing(0, 0, 0, 5),
  [vertical ? '&' : theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5, 0, 0, 0),
  },
}));

const DetailsGrid = styled(Grid, {
  shouldForwardProp: (p) => p !== 'vertical',
})(({ theme, vertical }) => ({
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  height: '40px',
  paddingTop: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    paddingRight: theme.spacing(3),
  },
  [vertical ? '&' : theme.breakpoints.down('md')]: {
    paddingRight: 0,
  },
  width: '100%',
}));

const ImageBox = styled(Box, {
  shouldForwardProp: (p) => p !== 'vertical',
})(({ theme, vertical }) => ({
  flexShrink: '0',
  width: '246px',
  [vertical ? '&' : theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const CardButton = styled(Button)(({ theme }) => ({
  whiteSpace: 'nowrap',
  '& .MuiButton-root': {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

function OfferCard(props) {
  const {
    offerId,
    incentive,
    lead,
    shouldLazyLoad,
    lazyProps,
    placementId,
    pageId,
    vertical = false,
  } = props;

  const theme = useTheme();

  const detailsUrl = `/myprofile/offers/${encodeURIComponent(offerId)}`;
  const primaryImage = getPrimaryIncentiveImage(incentive);
  const altText = `Get paid to live in 
    ${locationLabelText(incentive.locationText, incentive.regionText)}`;

  const [valueIcons, plusMore] = useMemo(() => {
    const valueTypes = incentive?.values?.map((value) => value.valueType) || [];
    const deduped = Array.from(new Set(valueTypes));
    const allIcons = deduped.map(offerTypeFromValue);
    const MAX_ICONS = 3;
    if (allIcons.length > MAX_ICONS) {
      return ([
        allIcons.slice(0, MAX_ICONS - 1),
        {
          title: allIcons.slice(MAX_ICONS - 1).map((icon) => icon.title).join(', '),
          count: allIcons.length - MAX_ICONS + 1,
        },
      ]);
    }
    return [allIcons, null];
  }, [incentive.values]);

  const impressionMeta = useMemo(() => ({ pageId }), [pageId]);
  const { trackView, trackClick } = useImpressions(
    toKey(types.INCENTIVE, incentive._id),
    placementId,
    impressionMeta,
  );

  const baseElement = useRef();
  const favoriteTarget = useRef();
  const applyTarget = useRef();
  const postUGC = useRef();

  const handleImpressionClick = useCallback((event) => {
    const elementsToTrack = new Map([
      [favoriteTarget.current, 'favorite'],
      [applyTarget.current, 'apply'],
      [baseElement.current, 'base'],
      [postUGC.current, 'post'],
    ]);

    const featureName = findImpressionFeature(event, elementsToTrack);
    trackClick({ feature: featureName });
  }, [trackClick]);

  const offerProgressSettings = useContext(OfferProgressContext) || {};
  if (Object.keys(offerProgressSettings).length === 0) {
    console.warn('offerProgressSettings required for accurate program card behavior');
  }
  const { steps } = offerProgressSettings;

  const currentStatus = useMemo(() => {
    const savedStatus = lead.status?.[0]?.status;
    // if the status is used in our progress tracker, use the same verbiage
    const moverFacingStatus = steps?.find((step) => step.statuses.includes(savedStatus));
    return moverFacingStatus ? {
      value: lead.status?.[0]?.status,
      title: moverFacingStatus.label,
    } : (
      leadStatusDetails.find((status) => (
        status.value === lead.status?.[0]?.status
      ))
    );
  }, [lead, steps]);

  const statusChipProps = useMemo(() => {
    // 'Closed' isn't a real status, so check for closed flag
    if (lead.closed) {
      return {
        label: 'Application Closed',
        variant: 'filled',
        sx: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.grey[300],
        },
      };
    }
    switch (lead.status?.[0]?.status) {
      case LeadStatus.NEW:
        return {
          label: 'Liked',
          variant: 'outlined',
          sx: {
            border: `1px solid ${theme.palette.grey[600]}`,
            color: theme.palette.grey[600],
          },
        };
      case LeadStatus.OFFERED:
      case LeadStatus.ACCEPTED:
        return {
          label: currentStatus.title,
          variant: 'outlined',
          sx: {
            border: `1px solid ${theme.palette.secondary.dark}`,
            color: theme.palette.secondary.dark,
          },
        };
      case LeadStatus.MOVED:
      case LeadStatus.INCENTIVE_DELIVERED:
        return {
          label: LeadStatus.MOVED,
          variant: 'filled',
          sx: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
          },
        };
      case LeadStatus.DECLINED:
        return {
          label: currentStatus.title,
          variant: 'filled',
          sx: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.error.main,
          },
        };
      default:
        return {
          label: currentStatus.title,
          variant: 'outlined',
          sx: {
            border: `1px solid ${theme.palette.grey[600]}`,
            color: theme.palette.grey[600],
          },
        };
    }
  }, [theme, lead.closed, lead.status, currentStatus]);

  const isMoved = [LeadStatus.MOVED, LeadStatus.INCENTIVE_DELIVERED]
    .includes(lead?.status?.[0].status);
  const isInactive = lead.closed || lead?.status?.[0].status === LeadStatus.DECLINED;

  const textColor = isInactive ? theme.palette.text.secondary : theme.palette.text.primary;

  return (
    <ImpressionTracker
      trackView={trackView}
      onClickCapture={handleImpressionClick}
      ref={baseElement}
    >
      <LinkCard href={detailsUrl} alt={altText} sx={{ height: '100%' }}>
        <CardContent vertical={vertical}>
          <ImageBox vertical={vertical}>
            <Box sx={{ position: 'relative' }}>
              <LinkCard.Overlays sx={{ padding: theme.spacing(1, 1.5) }}>
                <LinkCard.PillBar>{incentive.tags && (
                  getTagPills(incentive.tags).map(({ label, icon, iconColor }, i) => (
                    <LinkCard.Pill
                      key={`offer-${incentive._id}-pill-${i}`}
                      label={label}
                      icon={icon}
                      iconColor={iconColor}
                    />
                  ))
                )}
                </LinkCard.PillBar>
                <LinkCard.FavoriteButton entity={incentive} ref={favoriteTarget} />
              </LinkCard.Overlays>
              <LinkCard.Image
                borderRadius='8px'
                dimTop
                shouldLazyLoad={shouldLazyLoad}
                lazyProps={lazyProps}
              >
                <ResponsivePicture
                  image={primaryImage}
                  width={246}
                  height={200}
                  xs={1.5}
                  md={2}
                  xl={4}
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                    borderRadius: 'inherit',
                    backgroundColor: theme.palette.common.white,
                    opacity: isInactive ? '40%' : '100%',
                  }}
                />
              </LinkCard.Image>
            </Box>
          </ImageBox>
          <ContentPlacement vertical={vertical}>
            <Grid
              container
              sx={{
                height: { xs: '100%', md: vertical ? '100%' : 'auto' },
                flexDirection: { xs: 'column', md: vertical ? 'column' : 'row' },
              }}
            >
              {currentStatus && (
                <Grid item xs='auto' md={vertical ? 'auto' : 12} mb={1} marginX={{ xs: 0.5, md: vertical ? 0.5 : 0 }}>
                  <Chip
                    size='small'
                    {...statusChipProps}
                  />
                </Grid>
              )}
              <Grid item xs='auto' md={vertical ? 'auto' : 12} marginX={{ xs: 0.5, md: vertical ? 0.5 : 0 }}>
                <Typography sx={{ fontWeight: 600, fontSize: '16px', color: textColor }}>
                  {incentive.title || 'Total Value'}
                </Typography>
              </Grid>
              {(!vertical || !(isMoved || lead.incompleteTasks > 0)) && (
                <Grid item xs='auto' mb={2} marginX={{ xs: 0.5, md: vertical ? 0.5 : 0 }}>
                  <DetailsGrid container vertical={vertical}>
                    <Grid item minWidth={80}>
                      <Typography fontSize='16px' color={textColor}>
                        {incentive.totalValue ? `$${new Intl.NumberFormat().format(incentive.totalValue)}+` : ''}
                      </Typography>
                    </Grid>
                    <Grid item minWidth={80}>
                      <Box sx={{ position: 'relative', top: '1px' }}>
                        <Grid container spacing={1} justifyContent='end'>
                          {valueIcons.map(({ icon: ValueIcon, title }, i) => (
                            <Grid item key={`offer-${incentive._id}-value-${i}`}>
                              <Tooltip title={title} aria-label={`incentive value: ${title}`}>
                                <ValueIcon fontSize='13px' sx={{ color: textColor }} />
                              </Tooltip>
                            </Grid>
                          ))}
                          {plusMore && (
                            <Grid item>
                              <Tooltip title={plusMore.title} aria-label={`more incentive values: ${plusMore.title}`}>
                                <Typography variant='body2' fontSize='13px' color={textColor}>
                                  +{plusMore.count}
                                </Typography>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </DetailsGrid>
                </Grid>
              )}
              <Grid item xs md={vertical ? true : 12} sx={{ display: 'flex', alignItems: 'end' }}>
                <Grid container spacing={2} alignItems='center'>
                  {
                    (isMoved || lead.incompleteTasks > 0) && (
                      <Grid item xs md={vertical ? true : 'auto'} sx={{ maxWidth: '100%!important', mt: vertical ? 2 : 0 }}>
                        <IncompleteTasksCTA program={lead} incentive={incentive} />
                      </Grid>
                    )
                  }
                  <Grid item xs={12} md={vertical ? 12 : 'auto'}>
                    <IncentiveCTAButton
                      incentive={incentive}
                      Button={CardButton}
                      variant={isInactive ? 'outlined' : 'contained'}
                      disableElevation
                      fullWidth
                      color='primary'
                      ref={applyTarget}
                      linkDetailsForNew
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ContentPlacement>
        </CardContent>
      </LinkCard>
    </ImpressionTracker>
  );
}

export default OfferCard;
